import React from 'react';
import PropTypes from 'prop-types';

import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import 'moment/locale/pl';
import Markdown from '../shared/Markdown';

const ArticleViewer = ({ slug }) => {
  // eslint-disable-next-line no-use-before-define
  const { loading, data } = useQuery(GET_ARTICLE, {
    variables: {
      slug,
    },
  });

  const getLocalizedDate = () => {
    moment.locale('pl');
    const momentDate = moment(data.post.modified);
    return momentDate.format('lll');
  };

  const spinnerWrapper = (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
      <Spinner />
    </div>
  );

  const renderPostDetails = () => {
    const styles =
      data.post.featuredImage !== null
        ? {
            backgroundImage: `url(${data.post.featuredImage.node.mediaItemUrl})`,
          }
        : {
            backgroundColor: `grey`,
          };

    return (
      <>
        <div style={styles} className="viewer__background">
          <h1 className="viewer__title">{data.post.title}</h1>
          <h3>
            Modyfikowano:
            {` ${getLocalizedDate()}`}
          </h3>
        </div>
        <hr />
        <article className="viewer__content">
          <Markdown>{data.post.content}</Markdown>
        </article>
      </>
    );
  };

  return loading ? spinnerWrapper : <div className="viewer">{renderPostDetails()}</div>;
};

export default ArticleViewer;

ArticleViewer.propTypes = {
  slug: PropTypes.string.isRequired,
};

const GET_ARTICLE = gql`
  query GetArticle($slug: ID!) {
    post(id: $slug, idType: SLUG) {
      id
      content
      title
      modified
      slug
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`;
